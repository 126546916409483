@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4
  }
}

.object-cover {
  object-fit: cover;
}

.object-cover-center {
  object-position: center;
}

.flip-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: translate3d(0, 0, 0);
}

.flip-animation>span {
  animation: flipping 1s 5;
}

.flip-animation>span:nth-child(1) {
  animation-delay: 0.1s;
}

.flip-animation>span:nth-child(2) {
  animation-delay: 0.2s;
}

.flip-animation>span:nth-child(3) {
  animation-delay: 0.3s;
}

.flip-animation>span:nth-child(4) {
  animation-delay: 0.4s;
}

.flip-animation>span:nth-child(6) {
  animation-delay: 0.4s;
}

.flip-animation>span:nth-child(7) {
  animation-delay: 0.5s;
}

.flip-animation>span:nth-child(8) {
  animation-delay: 0.6s;
}

.flip-animation>span:nth-child(9) {
  animation-delay: 0.7s;
}

@keyframes flipping {

  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.overflow-x-auto::-webkit-scrollbar {
  height: 6px;
  width: 3px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #6b8439;
  border-radius: 10px;
}

@import 'aos/dist/aos.css';